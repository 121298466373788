html {
    font-size: 62.5%;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #444;
    padding-left: 2%;
    line-height: 1;
}

p {
    margin: 1rem;
}

ul {
    list-style-type: circle;
}

a:link, a:visited {
    color: #000;
}

table {
    padding: 1.6rem;
}
td {
    padding-right: 1.6rem;
}

details {
    margin: 1rem 0;
}

.nobg {
    background: none !important;
    border: none !important;
}

div.header-container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    padding: 3.2rem 0;
}
div.footer-container {
    max-width: 1200px;
    margin: 0 auto;
}
div.main-container-outer {
    background-color: #fff;
}
div.main-container {
    width: 96%;
    max-width: 1200px;
    margin: 0 auto;
    border-bottom: 1px solid #aaa;
}

body > header {
    width: 100%;
    background-color: #eee;
    margin: 0;
    border-bottom: 1px solid #aaa
}
body > header .picture {
    max-width: 50vw;
    margin: 0 auto 3.2rem;
}
body > header .picture img {
    max-height: 100%;
    max-width: 100%;
    border-radius: 50%;
    display: block;
}

div.profile {
    height: auto;
    text-align: center;
}

div.profile div.name {
    font-size: 10vw;
}
div.profile div.name img {
    display: none;
}
div.profile div.name span.title {
    font-weight: lighter;
}
div.profile div.name span.lastname {
    font-weight: bold;
}

div.profile div.headline {
    font-size: 7vw;
    font-weight: 200;
    color: #444;
}

div.profile div.headline div.txt span {
    white-space: nowrap;
}

header nav {
    margin: 3.2rem 0 0;
}
nav ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    padding: 0;
    margin: 0;
}
nav ul li {
    align-content: center;
}
div.profile nav ul li a:not(:has(i)) {
    font-size: 1.6rem;
}
div.profile nav ul li.active a {
    color: #000;
    text-decoration: underline;
}
div.profile nav ul li a {
    font-size: 1.9rem;
    color: #444;
    text-decoration: none;
    text-underline-position: under;
    text-decoration-thickness: 0.16rem;
}
div.profile nav ul li a:hover {
    text-decoration: underline;
}
footer nav {
    margin: 0 0 1.5rem 0;
}
footer .credit {
    font-size: 1.2rem;
}

div.container {
    margin: 2.4rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem;
    column-gap: 2.4rem;
}

section#column-right {
    order: 2;
}

section#column-left {
    order: 3;
}

section#column-left > a {
    text-decoration: none;
}

div.container h1.title {
    font-weight: 300;
    font-size: 3.2rem;
    text-align: center;
    margin: 0;
    padding: 0 1.6rem 1.4rem;
}

section.entry {
    padding: 1rem 1rem 3.6rem 1rem;
    background-color: #eee;
    position: relative;
    border-radius: 0.8rem;
    border: 0.1rem solid #aaa;
}

section.entry.never-collapse, section.entry.no-content, section.sub-group > section.entry, section.publications > section.entry {
    padding: 1rem;
}

section.entry details summary {
    padding-left: 1.6rem;
}

section.sub-group {
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: column;
    row-gap: 1rem;
}

section.sub-group section.entry {
    background-color: #f9f9f9;
    border-color: #ddd;
}

section.publications > div {
    display: flex;
    justify-content: space-between;
}

section.publications .filter {
    margin: 2.2rem 0 0;
    font-size: 1.6rem;
    font-family: 'Roboto Condensed', sans-serif;
}

section#column-left, section#column-right, section#single-column {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

section#column-right section.entry header label.toggle-expand {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

section.entry header div.entrydetails {
    padding: 1rem;
}

section.sub-group section.entry header div.entrydetails div {
    margin: 0.5rem 0;
}

section.entry header div.heading-above, section.entry header div.date {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.7rem;
    text-transform: uppercase;
    color: #222;
}
section.entry header div.date {
    font-size: 1.6rem;
}
section.entry header div.heading {
    font-size: 2.1rem;
}
section.publications header, section.talks header {
    line-height: 1.4;
}
section.publications header div.title, section.talks header div.title {
    font-size: 1.6rem;
}
section.entry header div.heading-below, section.entry header div.authors {
    font-weight: 300;
    font-size: 1.7rem;
    color: #555;
}
section.publications header div.authors {
    font-size: 1.6rem;
}
section.entry header div.links {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.6rem;
}

section.entry div.description {
    position: relative;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
}
section.publications div.description > p {
    margin-bottom: 0;
}

section.entry a.toggle-expand.link {
    margin-top: 2%;
}
section.publications a.toggle-expand.link {
    padding: 0;
}

figure img {
    height: auto;
    width: 100%;
}

figure.three img {
    width: 32%;
}

a.button {
    background-color: cadetblue;
    padding: 0.8rem;
    margin-right: 0.8rem;
    text-decoration: none;
}

footer {
    padding: 3.2rem 0;
    text-align: center;
}
footer a {
    font-size: 2rem;
    color: #333;
}

/* BEGIN Expanding Mechanism */

.expand-state {
    display:none;
}

.expand-state + .description {
    max-height: 0rem;
    overflow: hidden;
    transition: all .3s ease;
}

.toggle-expand {
    display: block;
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    color: #000;
    font-size: 1.6rem;
    padding: 1.6rem 0;
}

section#column-right .toggle-expand {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

section > .toggle-expand:before {
    content: "Read More";
    color: #000;
}

@keyframes fadein { from { opacity:0; } to { opacity:1; } }

div.fade-out {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    animation: fadein ease-in 1;
    animation-duration: .3s;
    background: -webkit-linear-gradient(
        rgba(238, 238, 238, 0) 0%,
        rgba(238, 238, 238, 1) 100%
    );
}

/* expand[number] moved to HTML */

/* END Expanding Mechanism */

/* BEGIN page.html */

div.pagecontainer {
    padding: 0 1.6rem;
    line-height: 1.4;
    font-size: 1.8rem;
}

div.pagecontainer h1, div.pagecontainer h2, div.pagecontainer h3, div.pagecontainer h4, div.pagecontainer h5, div.pagecontainer h6 {
    padding-left: 1.6rem;
}

div.pagecontainer > section {
    display: inline-block;
}

/* END page.html */

ul.blog {
    list-style-type: none;
    padding: 0;
}
ul.blog h2 {
    margin: 3.2rem 0 0 0;
}
ul.blog p.meta {
    margin: 0 1.6rem;
}
ul.blog h2 a {
    text-decoration: none;
    color: inherit;
}
ul.blog h2 a:hover {
    text-decoration: none;
    color: #666;
}
div#postnav {
    display: inline-block;
    width: 100%;
}
div#postnav > div {
    float: left;
    width: 33.3%;
    text-align: center;
}
div#postnav > div a {
    text-decoration: none;
    color: #444;
    font-weight: bold;
}

@media screen and (max-width: 960px) {
    section.experience div.description {
        display: none;
    }
}

@mixin wide-header {
    div.header-container {
        height: 18rem;
        flex-direction: row;
        width: 96%;
    }
    body > header .picture {
        max-width: revert;
        margin: revert;
    }
    body > header div.profile div.name {
        font-size: 6vw;
        padding: 0;
    }
    body > header div.profile {
        align-content: center;
        text-align: right;
        white-space: nowrap;
    }
    div.profile div.headline {
        font-size: 2.7rem;
    }
    div.profile nav {
        ul {
            justify-content: right;
        }
        a {
            font-size: 2rem;
        }
        a:not(:has(i)) {
            padding: 0 0.3rem;
            font-size: 1.6rem;
        }
    }
}
@media only screen and (min-width: 641px) and (orientation: landscape) {
    @include wide-header;
}
@media only screen and (min-width: 749px) {
    @include wide-header;
}
@media only screen and (min-width: 749px) and (orientation: portrait), (min-width: 961px) {
    body > header div.profile div.name {
        font-size: 5.3rem;
    }
    div.pagecontainer { padding: 2rem 4rem; }
    section.entry.hide-read-more {
        padding: 1rem;
    }
    section.entry header label.toggle-expand {
        display: none;
    }
    section.sub-group section.entry .toggle-expand {
        padding: 0;
    }
    section.hide-read-more div.description {
        max-height: 100%;
    }
    /* BEGIN Expanding Mechanism */
    .expand-state + .description { max-height: 10rem; }
    .toggle-expand.noexpand { display: none; }
    /* END Expanding Mechanism */
}

@media only screen and (min-width: 961px) {
    div.container {
        flex-direction: row;
    }

    div.container section h1.title{
        text-align: left;
    }

    section#column-right {
        order: 3;
        width: 60%;
    }

    section#column-left {
        order: 1;
        width: 40%;
    }
    div.pagecontainer { padding: 2rem 6rem; }
    figure.floatr {
        float: right;
        width: 400px;
    }
    figure.floatl {
        float: left;
        width: 400px;
    }
    figure.grid2 img {
        width: 49%;
    }
    figure.grid3 img {
        width: 32%;
    }
}

@media only screen and (min-width: 1056px) {
    div.pagecontainer { padding: 3.2rem 16rem; }
}

/* BEGIN Dark Mode */

@media (prefers-color-scheme: dark) {
    body {
        background-color: #000;
        color: #eee;
    }
    h1, h2, h3, h4, h5, h6 {
        color: #ddd;
    }
    a:link, a:visited {
        color: #eee;
    }
    div.main-container-outer {
        background-color: #000;
    }
    div.main-container {
        border-bottom-color: #222;
    }
    body > header {
        background-color: #222;
        border-bottom: none;
    }
    div.profile div.headline {
        color: #eee;
    }
    div.profile nav ul li a {
        color: #bbb;
    }
    div.profile nav ul li.active a {
        color: #fff;
    }
    section.entry {
        background-color: #111;
        border-color: #222;
    }
    section.sub-group section.entry {
        background-color: #000;
        border-color: #222;
    }
    section.entry header div.heading-above, section.entry header div.date {
        color: #ccc;
    }
    section.entry header div.heading-below, section.entry header div.authors {
        color: #aaa;
    }
    footer a {
        color: #ddd;
    }
    section > .toggle-expand:before {
        content: "Read More";
        color: #ddd;
    }
    div.fade-out {
        background: -webkit-linear-gradient(
            rgba(17, 17, 17, 0) 0%,
            rgba(17, 17, 17, 1) 100%
        );
    }
    ul.blog h2 a:hover {
        color: #aaa;
    }
    div#postnav > div a {
        color: #ccc;
    }
}

/* END Dark Mode */

